<template functionnal>
  <section class="section">
    <div class="template pb-4">
      <div class="template__content mb-2">
        <h1>Plan du site</h1>
        <br />

        <div>
          <h6>L'association</h6>
          <ul class="no-bullets">
            <li><router-link to="/notre-histoire">Notre histoire</router-link></li>
            <li><router-link to="/notre-equipe">Notre équipe</router-link></li>
            <li><router-link to="/nos-partenaires">Nos partenaires</router-link></li>
          </ul>
        </div>

        <div>
          <h6>Nos formations linguistiques</h6>
          <ul class="no-bullets">
            <li><router-link to="/nos-cours-de-francais">Cours de français</router-link></li>
            <li><router-link to="/nos-cours-d-alphabetisation">Alphabétisation</router-link></li>
            <li><router-link to="/nos-cours-de-francais-sur-mesure">Cours de français sur mesure</router-link></li>
          </ul>
        </div>

        <div>
          <h6>Apprendre le français autrement</h6>
          <ul class="no-bullets">
            <li><router-link to="/nos-cours-ouvert-a-tous">Ouvert à tous</router-link></li>
            <li><router-link to="/nos-cours-reserve-aux-apprenants">Réservé aux apprenants</router-link></li>
          </ul>
        </div>

        <h6><router-link to="/nos-accompagnements">Nos accompagnements individuels</router-link></h6>

        <h6><router-link to="/soutenez-nous">Soutenez-nous</router-link></h6>

        <h6><router-link to="/contact">Contact</router-link></h6>

        <h6><router-link to="/">Page d'accueil</router-link></h6>

        <h6><router-link to="/inscription">Inscription</router-link></h6>

        <h6><router-link to="/mentions-legales">Mentions légales</router-link></h6>

        <h6><router-link to="/politique-de-confidentialite">Politique de confidentialité</router-link></h6>

        <h6><router-link to="/plan-du-site">Plan du site</router-link></h6>

        <h6>
          <a target="_blank" href="https://fr-fr.facebook.com/pages/category/Nonprofit-Organization/Femmes-Initiatives-1639681316351534/">
            Page Facebook
          </a>
        </h6>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
a {
  color: #444;
  &:hover {
    text-decoration: underline;
  }
}
</style>
